import { Dispatch } from "redux";
import {
  FETCH_DAILY_PARAMETER_DATES,
  FETCH_DAILY_PARAMETER_DATES_SUCCESS,
  FETCH_DAILY_PARAMETER_DATES_FAILURE,
  FETCH_DAILY_PARAMETER_DATA,
  FETCH_DAILY_PARAMETER_DATA_SUCCESS,
  FETCH_DAILY_PARAMETER_DATA_FAILURE,
  CLEAR_SELECTED_DAILY_PARAMETER,
  FETCH_AVERAGE_WALKING_SPEED,
} from "../constants";
import { DailyParametersActionTypes } from "../types/dailyParametersActionTypes";
import { passiveParametersService } from "../../services/PassiveParameterService/passiveParametersService";

export function fetchDailyParameterDates(): DailyParametersActionTypes {
  return {
    type: FETCH_DAILY_PARAMETER_DATES,
  };
}

export function fetchDailyParameterDatesSuccess(
  data: any
): DailyParametersActionTypes {
  return {
    type: FETCH_DAILY_PARAMETER_DATES_SUCCESS,
    payload: data,
  };
}

export function fetchDailyParameterDatesError(
  error: string
): DailyParametersActionTypes {
  return {
    type: FETCH_DAILY_PARAMETER_DATES_FAILURE,
    payload: error,
  };
}

export function getDailyParameterDates(
  patientId: string,
  startDate: string,
  endDate: string
) {
  return async function action(dispatch: Dispatch) {
    dispatch(fetchDailyParameterDates());
    return await passiveParametersService
      .getDailyParametersDatesByRange(patientId, startDate, endDate)
      .then(
        (response: any) => dispatch(fetchDailyParameterDatesSuccess(response))
        //error => dispatch(someerror('woody', buzz, error))
      );
  };
}

export function fetchDailyParameterData(): DailyParametersActionTypes {
  return {
    type: FETCH_DAILY_PARAMETER_DATA,
  };
}

export function fetchDailyParameterDataSuccess(
  data: any
): DailyParametersActionTypes {
  return {
    type: FETCH_DAILY_PARAMETER_DATA_SUCCESS,
    payload: data,
  };
}

export function fetchDailyParameterDataError(
  error: string
): DailyParametersActionTypes {
  return {
    type: FETCH_DAILY_PARAMETER_DATA_FAILURE,
    payload: error,
  };
}

export function getDailyParameterData(accountGuid: string, date: Date) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchDailyParameterData());
    return passiveParametersService
      .getDailyParametersByDate(accountGuid, date)
      .then(
        (response: any) => dispatch(fetchDailyParameterDataSuccess(response))
        //error => dispatch(someerror('woody', buzz, error))
      );
  };
}

export function getAverageWalkingSpeedData(
  accountGuid: string,
  dateStart: Date,
  dateEnd: Date
) {
  return function action(dispatch: Dispatch) {
    return passiveParametersService
      .getAverageWalkingSpeed(accountGuid, dateStart, dateEnd)
      .then((response: any) => {
        dispatch({
          type: FETCH_AVERAGE_WALKING_SPEED,
          payload: response,
        });
      });
  };
}

export function clearSelectedDailyParameter(): DailyParametersActionTypes {
  return {
    type: CLEAR_SELECTED_DAILY_PARAMETER,
  };
}
