import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";

import { selectAccountId } from "../../../redux/selectors/accountSelectors";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { selectAccounts } from "../../../redux/selectors/adminSelectors";
import { useEffect, useState } from "react";
import {
  changeAccountRole,
  getAccounts,
  toggleUser2fa,
} from "../../../redux/actions/adminActions";
import { makeStyles } from "@mui/styles";
import { NewRole } from "../../../model/admin/newRole";
import { Account } from "../../../model/account/account";
import accountService from "../../../services/AccountService";
import NoData from "../../../components/NoData";
import PathCircularProgress from "../../../components/PathCircularProgress";
import LockResetIcon from "@mui/icons-material/LockReset";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { AccountTypeId } from "../../../model/account/accountTypeId";
import { Role } from "../../../model/account/role";

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: "fit-content",
    width: "fit-content",
    padding: "10px 20px 10px 20px",
    background: theme.palette.common.white,
  },
  header: {
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
  },
  form: {
    maxHeight: "500px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  select: {
    width: "140px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const AccountTypeIcon = (state: number) => {
  const tooltip = AccountTypeId[state];

  return (
    <Tooltip title={tooltip}>
      <Box>
        {
          {
            0: <SupervisorAccountIcon color="primary" />,
            1: <PersonIcon color="primary" />,
            2: <LocalHospitalIcon color="primary" />,
          }[state]
        }
      </Box>
    </Tooltip>
  );
};

const ModifyAccountComponent = () => {
  const classes = useStyles();
  const accounts = useSelector(selectAccounts);
  const accountGuid = useSelector(selectAccountId);
  const dispatch = useAppThunkDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAccounts()).then((response: any) => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRoleChanged(newRole: any, account: Account) {
    try {
      setIsLoading(true);
      accountService
        .switchAdmin(account.email, !newRole)
        .then((response: any) => {
          const data: NewRole = {
            role: newRole,
            accountId: account.accountGuid,
          };
          dispatch(changeAccountRole(data));
        })
        .then((response: any) => {
          setIsLoading(false);
        });
    } catch (error: any) {
      //hmmm
    }
  }

  function handleDeleteAccount(accountGuid: string) {
    try {
      setIsLoading(true);
      accountService
        .deleteAccount(accountGuid)
        .then((response: any) => {
          dispatch(getAccounts());
        })
        .then((response: any) => {
          setIsLoading(false);
        });
    } catch (error: any) {
      //hmmm
    }
  }

  function handleReset2fa(account: any) {
    if (account.isTwoFactorAuthenticationEnabled) {
      accountService.resetTwoFactor(account.accountGuid).catch((error: any) => {
        //This is not the error you are looking for;
      });
    }
  }

  const handleToggle2fa = (
    account: any,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setIsLoading(true);
      dispatch(
        toggleUser2fa(
          account.accountGuid,
          !account.isTwoFactorAuthenticationEnabled
        )
      ).then((response: any) => {
        setIsLoading(false);
      });
    } catch (error: any) {
      //hmmm
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography
          sx={{ padding: "5px 0px 5px 0px" }}
          variant="h5"
          gutterBottom
          component="h2"
        >
          Modify Account
        </Typography>
        {isLoading ? <PathCircularProgress /> : null}
      </Box>
      <Box className={classes.form}>
        {accounts.length ? (
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">First Name</StyledTableCell>
                  <StyledTableCell align="left">Last Name</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">AccountId</StyledTableCell>
                  <StyledTableCell align="left">Type</StyledTableCell>
                  <StyledTableCell align="left">Role</StyledTableCell>
                  <StyledTableCell align="left">
                    Two Factor Authentication
                  </StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts &&
                  accounts.map((account, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {account.firstName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {account.lastName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {account.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {account.accountGuid}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {AccountTypeIcon(account.accountTypeId)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Select
                          className={classes.select}
                          value={account.role}
                          onChange={(event) =>
                            handleRoleChanged(event.target.value, account)
                          }
                          //cant modify self or super admin account types
                          disabled={
                            account.accountGuid === accountGuid ||
                            account.role === Role.SuperAdmin
                          }
                        >
                          <MenuItem value={0}>Admin</MenuItem>
                          <MenuItem value={1}>User</MenuItem>
                          <MenuItem disabled value={2}>
                            Super Admin
                          </MenuItem>
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box>
                          <Switch
                            checked={account.isTwoFactorAuthenticationEnabled}
                            onChange={(event) =>
                              handleToggle2fa(account, event)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />

                          <Tooltip title="Reset 2FA">
                            <IconButton
                              color="primary"
                              size="large"
                              onClick={() => handleReset2fa(account)}
                            >
                              <LockResetIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {
                          //cant delete self or super admin account type
                          account.accountGuid !== accountGuid &&
                          account.role !== Role.SuperAdmin ? (
                            <ThreeDotsMenu
                              data={account.accountGuid}
                              handleAction={handleDeleteAccount}
                            />
                          ) : null
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData message="No accounts for you to manage right now" />
        )}
      </Box>
    </Box>
  );
};

export default ModifyAccountComponent;
