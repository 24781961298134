import React, {useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem} from "@mui/material";
import Select, {SelectChangeEvent} from "@mui/material/Select";

export interface IDropdownProps{
    options?: {
        label: string;
        value: string;
    }[],
    initialValue?: string;
    label?: string;
    value?: string;
    onSelect?: (value: string) =>  void;
}
const Dropdown = ({options, onSelect, initialValue, label}:IDropdownProps) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event: SelectChangeEvent) => {
        const newValue = event.target.value as string;
        if(onSelect){
            onSelect(newValue);
        }
        setValue(newValue);
    };

    return (
        <Box sx={{ minWidth: 160}}>
            <FormControl fullWidth >
                <InputLabel sx={{fontSize: '12px', marginTop: '-3px'}}>{label}</InputLabel>
                <Select
                    sx={{
                        fontSize: '12px',
                        maxHeight: '35px',
                        borderRadius: 100,
                        backgroundColor: "#F6F6F6",
                        paddingLeft: '5px',
                }}
                    value={value}
                    label={label}
                    onChange={handleChange}
                >
                    {options && options.map(({label, value}) => <MenuItem key={value+label} sx={{fontSize: '12px'}} value={value}>{label}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    );
};

export default Dropdown;