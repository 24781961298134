import {
  CLEAR_ACTIVE_TEST_DATA,
  CLEAR_ACTIVE_TEST_DATES,
  FETCH_ACTIVE_OVERVIEW_SUCCESS,
  FETCH_ACTIVE_TEST_DATA_SUCCESS,
  FETCH_ACTIVE_TEST_DATES_SUCCESS,
} from "../constants";
import { initialState } from "../system/SystemState";
import { ActiveState } from "../../model/active/ActiveState.ts";
import { ActiveActionTypes } from "../types/activeActionTypes";

export default function patientReducer(
  state = initialState.active,
  action: ActiveActionTypes
): ActiveState {
  switch (action.type) {
    case FETCH_ACTIVE_OVERVIEW_SUCCESS:
      return {
        ...state,
        overview: action.payload,
      };
    case FETCH_ACTIVE_TEST_DATES_SUCCESS:
      return {
        ...state,
        dates: action.payload,
      };
    case FETCH_ACTIVE_TEST_DATA_SUCCESS:
      return {
        ...state,
        selectedActiveTestData: action.payload,
      };

    case CLEAR_ACTIVE_TEST_DATES:
      return {
        ...state,
        dates: [],
      };

    case CLEAR_ACTIVE_TEST_DATA:
      return {
        ...state,
        selectedActiveTestData: [],
      };

    default:
      return state;
  }
}
