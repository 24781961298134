import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AverageWalkingSpeedDetailHeader from "./AverageWalkingSpeedDetailHeader";
import AverageWalkingSpeedDetailContent from "./AverageWalkingSpeedDetailContent";
import { dailyParameterDates } from "../../../../redux/selectors/dailyParametersSelectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
  },
}));

const AverageWalkingSpeedDetail: React.FC = () => {
  const classes = useStyles();
  const { patientId } = useParams();
  const selectorDates = useSelector(dailyParameterDates);
  const [selectedDate, setSelectedDate] = useState<Date>();

  return (
    <div className={classes.root}>
      <AverageWalkingSpeedDetailHeader
        patientId={patientId}
        dates={selectorDates}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <AverageWalkingSpeedDetailContent
        patientId={patientId}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default AverageWalkingSpeedDetail;
