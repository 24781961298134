import { SystemState } from "../system/SystemState";

export const dailyMetricDates = (state: SystemState) =>
  state.passiveParameters.dates;

export const selectedMetricDate = (state: SystemState) =>
  state.passiveParameters.selectedDate;

export const selectedDailyMetrics = (state: SystemState) =>
  state.passiveParameters.selectedDailyMetrics;

export const selectedAlertDetails = (state: SystemState) =>
  state.passiveParameters.selectedAlertDetails;

export const dailyMetricIsLoading = (state: SystemState) =>
  state.passiveParameters.isLoading;
