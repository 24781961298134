import { SystemState } from "../system/SystemState";

export const activeOverview = (state: SystemState) => state.active.overview;

export const activeTestDates = (state: SystemState) => state.active.dates;

export const activeTestData = (state: SystemState) =>
  state.active.selectedActiveTestData;

export const latestOverviewData = (state: SystemState) => state.active.overview;
