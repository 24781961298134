import { Account, AccountState } from "../../model/account/account";
import { AccountTypeId } from "../../model/account/accountTypeId";
import { Role } from "../../model/account/role";
import { ActiveState } from "../../model/active/ActiveState.ts";
import { AdminState } from "../../model/admin/adminState";
import { SettingsState } from "../../model/admin/settingsState";
import { DailyParametersState } from "../../model/DailyParametersState";
import { PassiveParametersState } from "../../model/passiveParametersState";
import { PatientsState } from "../../model/patient/PatientsState";

export interface SystemState {
  account: AccountState;
  patients: PatientsState;
  dailyParameters: DailyParametersState;
  passiveParameters: PassiveParametersState;
  active: ActiveState;
  admin: AdminState;
  settings: SettingsState;
}

const accountJson = localStorage.getItem("account");
const account: Account =
  accountJson !== null
    ? JSON.parse(accountJson)
    : {
        accountGuid: "",
        password: "",
        accountType: {
          accountTypeId: AccountTypeId.Account,
          description: "",
        },
        created: "",
        email: "",
        firstName: "",
        lastName: "",
        isTwoFactorAuthenticationEnabled: false,
        isTwoFactorAuthenticationSetupCompleted: false,
        isVerified: false,
        jwtToken: "",
        role: Role.User,
        //bug in backend - the getAll for account management is not populating "accountType" above, but is working with accountTypeId below. When this is fixed, accountTypeId should be deleted
        accountTypeId: 0,
        rememberMe: false,
        isTwoFactorAuthenticationPassed: false,
      };

export const initialState: SystemState = {
  account: {
    account: account,
    error: null,
    isLoading: false,
  },
  patients: {
    patients: [],
    patientTests: [],
    selectedTest: {
      dateTime: "",
      detail: null,
      tableData: null,
      isLoading: false,
    },
    isLoading: false,
  },
  dailyParameters: {
    dates: [],
    selectedDailyParameterData: [],
    averageWalkingSpeedData: [],
  },
  passiveParameters: {
    dates: [],
    selectedDate: null,
    selectedDailyMetrics: null,
    selectedAlertDetails: [],
    isLoading: false,
  },
  active: {
    overview: null,
    dates: [],
    selectedDate: null,
    selectedActiveTestData: [],
  },
  admin: {
    accounts: [],
    invitationCodes: [],
  },
  settings: {
    dailyMetricVisibility: {
      pressureDistribution: false,
      temperatureDistribution: false,
      dailyParameters: false,
      activityTracker: false,
    },
    dailyParameterVisibility: {
      totalDistance: false,
      averageWalkingSpeed: false,
      averageAsymmetry: false,
      averageCadence: false,
      totalSteps: false,
      averageVariability: false,
      averageCycleDuration: false,
      averageSwingSpeed: false,
      averageStrideLength: false,
      averageSwing: false,
    },
    patientTestVisibility: {
      latest10MWT: false,
      latestTUG: false,
      latestCustom: false,
    },
  },
};
