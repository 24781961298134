import { Dispatch } from "redux";
import {
  FETCH_SETTINGS,
  FETCH_SETTINGS_FAILURE,
  FETCH_SETTINGS_SUCCESS,
} from "../constants";
import { SettingsActionTypes } from "../types/settingsActionTypes";
import { settingsService } from "../../services/settingsService";

export function fetchSettings(): SettingsActionTypes {
  return {
    type: FETCH_SETTINGS,
  };
}

export function fetchSettingsSuccess(data: any) {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: data,
  };
}

export function fetchSettingsError(error: string): SettingsActionTypes {
  return {
    type: FETCH_SETTINGS_FAILURE,
    payload: error,
  };
}

export function getSettings() {
  return function action(dispatch: Dispatch) {
    dispatch(fetchSettings());
    return settingsService.getAll().then(
      (response: any) => dispatch(fetchSettingsSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}
