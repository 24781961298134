import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useAppThunkDispatch } from "../../../../redux/configureStore";
import { Patient } from "../../../../model/patient/patient";
import {
  getDateRanges,
  resetTimeforDate,
} from "../../../../helpers/utilityFunctions/utilityFunctions";
import { dailyParameterDates } from "../../../../redux/selectors/dailyParametersSelectors";
import {
  clearSelectedDailyParameter,
  getDailyParameterData,
  getDailyParameterDates,
} from "../../../../redux/actions/dailyParameterActions";
import MultiDatePicker from "../../../../components/documented/MultiDatePicker/MultiDatePicker";
import { DateRange } from "../../../../model/dateRange";
import { selectedMetricDate } from "../../../../redux/selectors/passiveParametersSelectors";
import { setDailyMetricDate } from "../../../../redux/actions/dailyMetricActions";
import PatientDetailsHeader from "../../../../components/Account/PatientDetailsHeader";

const useStyles = makeStyles(() => ({
  actions: {
    display: "flex",
  },
  backButton: {
    width: "40px",
    height: "40px",
  },
  backIcon: {
    transform: "scale(1.3)",
  },
}));

interface Props {
  patientId?: string;
}

export const DailyParametersHomeHeader: React.FC<Props> = ({ patientId }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const dates = useSelector(dailyParameterDates);
  const [selectedPatient] = useState<Patient>();
  const selectedDailyMetricDate = useSelector(selectedMetricDate);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const [selectedMonth, setSelectedMonth] = React.useState<Date>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  //this triggers when date in selector changes
  useEffect(() => {
    if (selectedDate === undefined) return;

    const dateToFind = resetTimeforDate(selectedDate);
    const index = dates.findIndex((date: Date) => {
      return date.toString() === dateToFind;
    });
    changeSelectedDate(index.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  //this fetches the data for each month when the month changes
  useEffect(() => {
    if (selectedMonth !== undefined) {
      setIsLoading(true);
      const dateRange: DateRange = getDateRanges(selectedMonth);
      dispatch(
        getDailyParameterDates(
          patientId || "",
          dateRange.startDate,
          dateRange.endDate
        )
      ).then(() => {
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    //check if a selected date exists in store, if so, try and set that as date here in the first instance. If not, try and populate the page with todays date
    const date = selectedDailyMetricDate || new Date();
    const dateRange: DateRange = getDateRanges(date, true);
    dispatch(
      getDailyParameterDates(
        patientId || "",
        dateRange.startDate,
        dateRange.endDate
      )
    ).then((response: any) => {
      //this is a bit of a hack - async sometimes isnt updating on time when fetching dates
      if (!response && !response.payload) return;
      const dateToFind = resetTimeforDate(new Date(date));
      const index = response.payload.findIndex((date: Date) => {
        return date.toString() === dateToFind;
      });
      if (index !== -1) {
        //date was found, so select it
        setSelectedDate(new Date(date));
        changeSelectedDate(index.toString());
      } else {
        //specific date was not found, so select latest date in range if there is one
        if (Array.isArray(response.payload) && response.payload.length) {
          const latestDate = response.payload.reduce(function (a: any, b: any) {
            return a > b ? a : b;
          });
          const dateToFind = resetTimeforDate(new Date(latestDate));
          const index = response.payload.findIndex((date: Date) => {
            return date.toString() === dateToFind;
          });
          setSelectedDate(new Date(latestDate));
          changeSelectedDate(index.toString());
        } else {
          //as a last resort, just select todays date
          setSelectedDate(new Date());
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeSelectedDate = (index: string) => {
    dispatch(clearSelectedDailyParameter());
    const selectedDate = dates[Number(index)];
    dispatch(setDailyMetricDate(selectedDate));
    dispatch(getDailyParameterData(patientId!, selectedDate));
  };

  return (
    <Box>
      <PatientDetailsHeader selectedPatient={selectedPatient} />
      <IconButton
        sx={{ mt: 1 }}
        className={classes.backButton}
        color="primary"
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon className={classes.backIcon} />
      </IconButton>

      <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
        <Typography variant="h5" sx={{ mt: 1, pr: 2 }}>
          <strong>Daily Parameters</strong>
        </Typography>

        <MultiDatePicker
          dates={dates}
          selectedDate={selectedDate}
          isLoading={isLoading}
          setSelectedDate={setSelectedDate}
          setSelectedMonth={setSelectedMonth}
        />
      </Box>
    </Box>
  );
};

export default DailyParametersHomeHeader;
