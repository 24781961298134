import {
  FETCH_DAILY_METRIC_DATES,
  FETCH_DAILY_METRIC_DATES_SUCCESS,
  FETCH_DAILY_METRIC_DATES_FAILURE,
  FETCH_DAILY_METRICS,
  FETCH_DAILY_METRICS_SUCCESS,
  FETCH_DAILY_METRICS_FAILURE,
  CLEAR_SELECTED_DAILY_METRICS,
  FETCH_ALL_ALERT_DETAIL,
  FETCH_ALL_ALERT_DETAIL_SUCCESS,
  FETCH_ALL_ALERT_DETAIL_FAILURE,
  CLEAR_ALL_ALERT_DETAIL,
  SELECT_DAILY_METRIC_DATE,
} from "../constants";
import { PassiveParametersState } from "../../model/passiveParametersState";
import { PassiveParametersActionTypes } from "../types/passiveParametersActionTypes";
import { initialState } from "../system/SystemState";

export default function passiveParametersReducer(
  state = initialState.passiveParameters,
  action: PassiveParametersActionTypes
): PassiveParametersState {
  switch (action.type) {
    case FETCH_DAILY_METRIC_DATES:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DAILY_METRIC_DATES_SUCCESS:
      return {
        ...state,
        dates: action.payload,
        isLoading: false,
      };
    case FETCH_DAILY_METRIC_DATES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case SELECT_DAILY_METRIC_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case FETCH_DAILY_METRICS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DAILY_METRICS_SUCCESS:
      return {
        ...state,
        selectedDailyMetrics: action.payload,
        isLoading: false,
      };
    case FETCH_DAILY_METRICS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_SELECTED_DAILY_METRICS:
      return {
        ...state,
        selectedDate: null,
        selectedDailyMetrics: null,
      };
    case FETCH_ALL_ALERT_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ALL_ALERT_DETAIL_SUCCESS:
      return {
        ...state,
        selectedAlertDetails: action.payload,
        isLoading: false,
      };
    case FETCH_ALL_ALERT_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_ALL_ALERT_DETAIL:
      return {
        ...state,
        selectedAlertDetails: [],
      };
    default:
      return state;
  }
}
