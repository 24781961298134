import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FlightIcon from "@mui/icons-material/Flight";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Tooltip, Typography } from "@mui/material";

import ActivityTrackerCard from "../../components/Cards/ActivityTrackerCard";
import AlertCard from "../../components/Cards/AlertCard";
import DailyParametersCard from "../../components/Cards/DailyParametersCard";
import MultiDatePicker from "../../components/documented/MultiDatePicker/MultiDatePicker";
import { useClientTheme } from "../../helpers/hooks/useClientTheme";
import {
  clearSelectedDailyMetrics,
  getDailyMetricDatesByRange,
  getDailyMetrics,
  setDailyMetricDate,
} from "../../redux/actions/dailyMetricActions";
import { useAppThunkDispatch } from "../../redux/configureStore";
import {
  dailyMetricDates,
  selectedDailyMetrics,
  selectedMetricDate,
} from "../../redux/selectors/passiveParametersSelectors";
import { PassiveParameterType } from "../../model/passive/passiveParameterType";
import { HeatmapData } from "../../model/passive/heatmapData";
import {
  compareTimezoneToHCP,
  getDateRanges,
  isDatesEqual,
  resetTimeforDate,
} from "../../helpers/utilityFunctions/utilityFunctions";
import { DailyMetricCardVisibility } from "../../model/config/DailyMetricCardVisibility";
import { Temperature } from "../../model/temperature";
import { DateRange } from "../../model/dateRange";
import NoData from "../../components/NoData";
import { dailyMetricsVisibilitySettings } from "../../redux/selectors/settingsSelectors";

interface Props {
  selectedPatientId?: string;
  selectedDate: Date | undefined;
  setSelectedDate: Dispatch<SetStateAction<Date | undefined>>;
}

export const DailyMetricsPage: React.FC<Props> = ({
  selectedPatientId,
  selectedDate,
  setSelectedDate,
}) => {
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const { theme } = useClientTheme();
  const dailyMetricVisibility: DailyMetricCardVisibility = useSelector(
    dailyMetricsVisibilitySettings
  );

  //Dates
  const dates = useSelector(dailyMetricDates);
  const selectedDailyMetricDate = useSelector(selectedMetricDate);
  const [selectedDateValue, setSelectedDateValue] = React.useState<Date>();
  const [selectedMonth, setSelectedMonth] = React.useState<Date>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const selectedData = useSelector(selectedDailyMetrics);

  //this triggers when date in selector changes
  useEffect(() => {
    if (selectedDate === undefined) return;

    const dateToFind = resetTimeforDate(selectedDate);
    const index = dates.findIndex((date: Date) => {
      return date.toString() === dateToFind;
    });
    changeDailyMetric(index.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  //this fetches the data for each month when the month changes
  useEffect(() => {
    if (selectedMonth !== undefined) {
      setIsLoading(true);
      const dateRange: DateRange = getDateRanges(selectedMonth);
      dispatch(
        getDailyMetricDatesByRange(
          selectedPatientId || "",
          dateRange.startDate,
          dateRange.endDate
        )
      ).then(() => {
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    if (selectedDate === undefined) return;
    const dateToFind = resetTimeforDate(selectedDate);
    const index = dates.findIndex((date: Date) => {
      return date.toString() === dateToFind;
    });
    changeDailyMetric(index.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  useEffect(() => {
    //check if a selected date exists in store, if so, try and set that as date here in the first instance. If not, try and populate the page with todays date
    const date = selectedDailyMetricDate || new Date();
    const dateRange: DateRange = getDateRanges(date, true);
    dispatch(
      getDailyMetricDatesByRange(
        selectedPatientId || "",
        dateRange.startDate,
        dateRange.endDate
      )
    ).then((response: any) => {
      //this is a bit of a hack - async sometimes isnt updating on time when fetching dates
      if (!response && !response.payload) return;
      const dateToFind = resetTimeforDate(new Date(date));
      const index = response.payload.findIndex((date: Date) => {
        return date.toString() === dateToFind;
      });
      if (index !== -1) {
        //date was found, so select it
        setSelectedDate(new Date(date));
        changeDailyMetric(index.toString());
      } else {
        //specific date was not found, so select latest date in range if there is one
        if (Array.isArray(response.payload) && response.payload.length) {
          const latestDate = response.payload.reduce(function (a: any, b: any) {
            return a > b ? a : b;
          });
          const dateToFind = resetTimeforDate(new Date(latestDate));
          const index = response.payload.findIndex((date: Date) => {
            return date.toString() === dateToFind;
          });
          setSelectedDate(new Date(latestDate));
          changeDailyMetric(index.toString());
        } else {
          //as a last resort, just select todays date
          setSelectedDate(new Date());
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeDailyMetric = (index: string) => {
    dispatch(clearSelectedDailyMetrics());
    if (index === "-1") return;
    const selectedDate = dates[Number(index)];
    setSelectedDateValue(selectedDate);
    dispatch(setDailyMetricDate(selectedDate));
    dispatch(
      getDailyMetrics(
        selectedPatientId!,
        selectedDate,
        theme.metrics.temperature.unit
      )
    );
  };

  const handleClick = (arg: string) => {
    navigate(
      `/dashboard/patients/${selectedPatientId}/passive/${arg.toLowerCase()}`
    );
  };

  const handleDailyParamClick = () => {
    navigate(`/dashboard/patients/${selectedPatientId}/dailyparameters`);
  };

  const handleActivityTrackerClick = () => {};

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="h5" sx={{ mt: 1, pr: 2 }}>
          <strong>Daily Metrics</strong>
        </Typography>

        <MultiDatePicker
          dates={dates}
          selectedDate={selectedDate}
          isLoading={isLoading}
          setSelectedDate={setSelectedDate}
          setSelectedMonth={setSelectedMonth}
        />

        <Box
          sx={{
            ml: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {selectedData &&
          selectedData?.dailyMetricsStatistics.hasBeenTraveling ? (
            <Tooltip title="This patient has been traveling across time zones during the selected day. All the times are reported in the patient's time zone.">
              <FlightIcon
                sx={{
                  transform: "scale(1.3)",
                }}
                color="error"
              />
            </Tooltip>
          ) : null}
          {selectedData &&
          compareTimezoneToHCP(
            selectedData?.dailyMetricsStatistics.endOfDayTimezone,
            selectedData?.dailyMetricsStatistics.hasBeenTraveling
          ) ? (
            <Tooltip title="This patient is in a different time zone than you on that day. All the times are reported in the patient’s time zone.">
              <AccessTimeIcon
                sx={{
                  transform: "scale(1.3)",
                }}
                color="error"
              />
            </Tooltip>
          ) : null}
        </Box>
      </Box>

      {selectedData ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {dailyMetricVisibility.pressureDistribution ? (
            <Grid item>
              <AlertCard
                isAlert={isDatesEqual(
                  new Date(selectedData?.latestAlertsPressure?.dateTime!),
                  new Date(selectedDateValue!)
                )}
                parameterType={PassiveParameterType.AlarmPressure}
                onClick={handleClick}
                popoverContent="The heat map is reflective of the pressure distribution when the
                  highest level alarm was triggered in the last 24 hours."
                heatmapData={
                  new HeatmapData(
                    selectedData?.latestAlertsPressure
                      ? selectedData?.latestAlertsPressure.sensorDataLeft
                      : undefined,
                    selectedData?.latestAlertsPressure
                      ? selectedData?.latestAlertsPressure.sensorDataRight
                      : undefined
                  )
                }
              />
            </Grid>
          ) : null}
          {dailyMetricVisibility.temperatureDistribution ? (
            <Grid item>
              <AlertCard
                isAlert={isDatesEqual(
                  new Date(selectedData?.latestAlertsTemperature?.dateTime!),
                  new Date(selectedDateValue!)
                )}
                parameterType={PassiveParameterType.AlarmTemperature}
                onClick={handleClick}
                popoverContent={`The heat map is reflecting the temperature distribution in feet when a temperature difference between the feet greater than ${
                  theme.metrics.temperature.unit === Temperature.Celsius
                    ? "2.2 degrees centigrade"
                    : "35.96 degrees fahrenheit"
                } was detected in the last 25 hours.`}
                heatmapData={
                  new HeatmapData(
                    selectedData?.latestAlertsTemperature
                      ? selectedData?.latestAlertsTemperature.sensorDataLeft
                      : undefined,
                    selectedData?.latestAlertsTemperature
                      ? selectedData?.latestAlertsTemperature.sensorDataRight
                      : undefined
                  )
                }
                unit={theme.metrics.temperature.unit}
              />
            </Grid>
          ) : null}
          {dailyMetricVisibility.dailyParameters ? (
            <Grid item>
              <DailyParametersCard
                large
                title="Daily Parameters"
                onClick={() => handleDailyParamClick()}
                popoverContent="Gait Parameters for the past day while the user was wearing the insoles"
                data={selectedData?.dailyMetrics}
              />
            </Grid>
          ) : null}
          {dailyMetricVisibility.activityTracker &&
          selectedData &&
          selectedData.dailyMetrics.length > 0 ? (
            <Grid item>
              <ActivityTrackerCard
                large
                title="Activity Tracker"
                onClick={() => handleActivityTrackerClick()}
                popoverContent="Distribution of activity during the past day. Insole Non-Active
                    Time refers to times when insole is not active.
                    Insole - Active Time is divided between sedentary and active hours."
                data={selectedData.dailyMetrics}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <NoData message="there is no data for this day" />
      )}
    </Box>
  );
};

export default DailyMetricsPage;
