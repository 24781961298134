import { fetch } from "../helpers/api/api";
import { handleResponse } from "../helpers/handleResponse";

const baseUrl = `${process.env.REACT_APP_API_URL}/setting`;

export const settingsService = {
  getAll,
};

function getAll() {
  return fetch(`${baseUrl}/all`).then((response: any) =>
    handleResponse(response)
  );
}
