import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { useClientTheme } from "../../../helpers/hooks/useClientTheme";
import { Grid, Typography } from "@mui/material";
import Heatmap from "../../../components/Heatmap/Heatmap";
import {
  selectedDailyMetrics,
  selectedAlertDetails,
  dailyMetricIsLoading,
} from "../../../redux/selectors/passiveParametersSelectors";
import { HeatmapTransforms } from "../../../components/Heatmap/heatmapTransforms";
import PressureInterpretationTableComponent from "./PressureInterpretationTableComponent";
import TemperatureInterpretationTableComponent from "./TemperatureInterpretationTableComponent";
import { Alert } from "../../../model/passive/alertMoment";
import { PassiveParameterType } from "../../../model/passive/passiveParameterType";
import { HeatmapData } from "../../../model/passive/heatmapData";
import heatmapBackground from "../../../assets/images/sensorInsoles.png";
import heatmapOverlay from "../../../assets/images/Mask_Lmdpi.png";
import {
  clearAllAlertDetails,
  getAllAlertDetail,
} from "../../../redux/actions/dailyMetricActions";
import {
  getEnumKeyByEnumValue,
  isNullOrUndefined,
} from "../../../helpers/utilityFunctions/utilityFunctions";
import {
  pressureDistributionText,
  temperatureDistributionText,
} from "./TextResources";
import { HeatmapSizeEnum } from "../../../components/Heatmap/HeatmapSizeEnum";
import { AlertList } from "../../../components/documented/AlertList/AlertList";

const defaultTransforms: HeatmapTransforms = {
  scaleX: 0,
  scaleY: 0,
  translateX: 0,
  translateY: 0,
};

const pressureTransforms: HeatmapTransforms = {
  scaleX: 0.8,
  scaleY: 0.9,
  translateX: -25,
  translateY: -50,
};

const temperatureTransforms: HeatmapTransforms = {
  scaleX: 0.81,
  scaleY: 0.89,
  translateX: -50,
  translateY: -40,
};

const selectTransforms = (type: PassiveParameterType) => {
  switch (type) {
    case PassiveParameterType.AlarmPressure:
      return pressureTransforms;
    case PassiveParameterType.AlarmTemperature:
      return temperatureTransforms;
    default:
      return defaultTransforms;
  }
};

interface Props {
  patientId?: string;
  alertType: PassiveParameterType;
}

export const AlertDetailsComponent: React.FC<Props> = ({
  patientId,
  alertType,
}) => {
  const dispatch = useAppThunkDispatch();
  const { theme } = useClientTheme();

  const [selectedMomentId, setSelectedMomentId] = React.useState(0);

  const selectedData = useSelector(selectedDailyMetrics);
  const selectedAlertData = useSelector(selectedAlertDetails);
  const loading = useSelector(dailyMetricIsLoading);

  const createHeatmapData = () => {
    const momentList = selectedAlertData.map((item) => {
      return item.data.find((alert) => alert.id === selectedMomentId);
    });

    //the selected moment may appear in several lists - so just use the first to render heatmap data
    if (!isNullOrUndefined<Array<Alert | undefined>>(momentList)) {
      return new HeatmapData(
        momentList[0]?.sensorDataLeft,
        momentList[0]?.sensorDataRight
      );
    } else {
      return new HeatmapData("[]", "[]");
    }
  };

  useEffect(() => {
    const processedType =
      getEnumKeyByEnumValue(PassiveParameterType, alertType) || "";

    switch (alertType) {
      case PassiveParameterType.AlarmPressure:
        dispatch(getAllAlertDetail(patientId || "", processedType));

        if (!isNullOrUndefined(selectedData?.latestAlertsPressure)) {
          setSelectedMomentId(selectedData?.latestAlertsPressure.id!);
        }
        break;
      case PassiveParameterType.AlarmTemperature:
        dispatch(
          getAllAlertDetail(
            patientId || "",
            processedType,
            theme.metrics.temperature.unit
          )
        );
        if (!isNullOrUndefined(selectedData?.latestAlertsTemperature)) {
          setSelectedMomentId(selectedData?.latestAlertsTemperature.id!);
        }
        break;
      default:
        break;
    }
    return () => {
      dispatch(clearAllAlertDetails());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: number
  ) => {
    setSelectedMomentId(id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={8}>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="body1">
            {alertType === PassiveParameterType.AlarmPressure
              ? pressureDistributionText
              : temperatureDistributionText}
          </Typography>
          <Heatmap
            height={260}
            width={260}
            magnitude={HeatmapSizeEnum.Large}
            data={createHeatmapData()}
            type={alertType!}
            unit={theme.metrics.temperature.unit}
            background={heatmapBackground}
            overlay={heatmapOverlay}
            transforms={selectTransforms(alertType!)}
          />
          {alertType === PassiveParameterType.AlarmPressure ? (
            <PressureInterpretationTableComponent />
          ) : (
            <TemperatureInterpretationTableComponent />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Grid container justifyContent="center" alignItems="center">
          <AlertList
            alertType={alertType}
            selectedAlertData={selectedAlertData}
            selectedMomentId={selectedMomentId}
            isLoading={loading}
            onClick={handleListItemClick}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlertDetailsComponent;
