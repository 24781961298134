import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import DailyMetricsPage from "../../DailyMetricsPage";
import DetailCard from "../../../components/Cards/DetailCard";
import { ActiveType } from "../../../model/activeType";
import Scrollable from "../../../components/Scrollable";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getActiveOverview } from "../../../redux/actions/activeActions";
import { activeOverview } from "../../../redux/selectors/activeSelectors";
import { patientTestVisibilitySettings } from "../../../redux/selectors/settingsSelectors";
import { selectPatients } from "../../../redux/selectors/patientSelectors";
import { Patient } from "../../../model/patient/patient";
import PatientDetailsHeader from "../../../components/Account/PatientDetailsHeader";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroContent: {
    marginTop: 30,
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
}));

const PatientDetail: React.FC = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const classes = useStyles();
  const activeOverviewData = useSelector(activeOverview);

  const patientTestVisibility = useSelector(patientTestVisibilitySettings);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  const patients = useSelector(selectPatients);
  const [selectedPatient, setSelectedPatient] = useState<Patient>();

  useEffect(() => {
    if (patientId) dispatch(getActiveOverview(patientId));
    const selectedPatient = patients.find((p) => p.accountGuid === patientId);
    setSelectedPatient(selectedPatient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const handleClick = (e: any) => {
    navigate(`/dashboard/patients/${patientId}/active/${e}`);
  };

  return (
    <div className={classes.root}>
      <Scrollable>
        <PatientDetailsHeader selectedPatient={selectedPatient} />

        <DailyMetricsPage
          selectedPatientId={patientId}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        <>
          <div className={classes.heroContent}>
            <Typography variant="h5" align="left" gutterBottom>
              <strong>Tests</strong>
            </Typography>
          </div>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item>
              {patientTestVisibility?.latestCustom &&
              activeOverviewData?.latestCustom ? (
                <DetailCard
                  title={ActiveType["_CTEST"]}
                  icon="_TUGT"
                  onClick={() => handleClick("_CTEST")}
                  popoverContent="Access the raw files of the custom test recordings."
                  content="Click here to access the raw files of the custom test recordings"
                />
              ) : null}
            </Grid>
            <Grid item>
              {patientTestVisibility?.latest10MWT &&
              activeOverviewData?.latest10MWT ? (
                <DetailCard
                  title={ActiveType["_10MWT"]}
                  icon="_10MWT"
                  onClick={() => handleClick("_10MWT")}
                  popoverContent="The 10-Metre Walk Test is a validated performance measure used to assess walking speed in metres per second over a short distance. It can be employed to determine functional mobility, gait, and vestibular function."
                  content={activeOverviewData.latest10MWT}
                />
              ) : null}
            </Grid>
            <Grid item>
              {patientTestVisibility?.latestTUG &&
              activeOverviewData?.latestTUG ? (
                <DetailCard
                  title={ActiveType["_TUG"]}
                  icon="_TUGT"
                  onClick={() => handleClick("_TUG")}
                  popoverContent="Timed Up and Go (TUG) Test is a validated test to understand a person's mobility and falls risk. A greater TUG score indicates reduced mobility and balance."
                  content={activeOverviewData?.latestTUG}
                />
              ) : null}
            </Grid>
          </Grid>
        </>
      </Scrollable>
    </div>
  );
};

export default PatientDetail;
