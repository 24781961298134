import { FETCH_SETTINGS_SUCCESS } from "../constants";
import { initialState } from "../system/SystemState";
import { SettingsActionTypes } from "../types/settingsActionTypes";
import { SettingsState } from "../../model/admin/settingsState";
import { GetSetting } from "../../helpers/utilityFunctions/utilityFunctions";

export default function settingsReducer(
  state = initialState.settings,
  action: SettingsActionTypes
): SettingsState {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      const dailyMetricVisibility = GetSetting(
        action.payload,
        "DailyMetricsVisibility"
      );

      const dailyParameterVisibility = GetSetting(
        action.payload,
        "DailyParametersVisibility"
      );

      const patientTestVisibility = GetSetting(
        action.payload,
        "PatientTestVisibility"
      );

      return {
        ...state,
        dailyMetricVisibility: dailyMetricVisibility,
        dailyParameterVisibility: dailyParameterVisibility,
        patientTestVisibility: patientTestVisibility,
      };

    default:
      return state;
  }
}
