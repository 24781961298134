import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import Dropdown, { IDropdownProps } from "../../../../components/Dropdown";
import { useSelector } from "react-redux";
import {
  averageWalkingSpeedData,
  dailyParameterDates,
} from "../../../../redux/selectors/dailyParametersSelectors";
import { useAppThunkDispatch } from "../../../../redux/configureStore";
import { getAverageWalkingSpeedData } from "../../../../redux/actions/dailyParameterActions";
import { getDateEndByRange } from "../../../../helpers/utilityFunctions/utilityFunctions";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  patientId?: string;
  selectedDate: Date | undefined;
}

// const colors = ['rgba(25,128,44,0.06)', 'rgba(217,11,11,0.06)',];
const colors = ["rgba(173,173,173,0.06)", "rgba(173,173,173,0.06)"];

type rangeType = "week" | "month" | "year";

const dropdownOptions: IDropdownProps["options"] = [
  {
    label: "1 Week",
    value: "week",
  },
  {
    label: "1 Month",
    value: "month",
  },
  {
    label: "1 Year",
    value: "year",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    margin: "0px 40px 0px 0px",
    backgroundColor: "white",
  },
  content: {
    display: "flex",
    flexFlow: "column",
    backgroundColor: "white",
  },
  backButton: {
    width: "40px",
    height: "40px",
  },
  backIcon: {
    transform: "scale(1.3)",
  },
}));

export const AverageWalkingSpeedDetailContent: React.FC<Props> = ({
  patientId,
  selectedDate,
}) => {
  const classes = useStyles();

  const dispatch = useAppThunkDispatch();
  const selectorDates = useSelector(dailyParameterDates);
  const averageWalkingSpeed = useSelector(averageWalkingSpeedData);

  const [range, setRange] = useState<rangeType>("week");

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  //To split and colorize background of chart
  useEffect(() => {
    Chart.register({
      id: "backgroundSplitter",
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        const { top, bottom, left, right } = chart.chartArea;

        const segmentHeight = (bottom - top) / colors.length;

        for (let i = 0; i < colors.length; i++) {
          ctx.fillStyle = colors[i];
          ctx.fillRect(
            left,
            top + segmentHeight * i,
            right - left,
            segmentHeight
          );
        }
      },
    });
  }, []);

  useEffect(() => {
    if (selectorDates.length === 0) {
      //dispatch(getDailyParameterDates(patientId || ""))
    }
  }, [dispatch, patientId, selectorDates]);

  useEffect(() => {
    if (selectedDate) {
      setIsDataLoading(() => true);
      dispatch(
        getAverageWalkingSpeedData(
          patientId!,
          getDateEndByRange(selectedDate, range),
          selectedDate
        )
      );
      setIsDataLoading(() => false);
    }
  }, [selectedDate, range, dispatch, patientId]);

  const rangeDates = useMemo(() => {
    if (selectedDate) {
      const dates = [];

      const currentDate = getDateEndByRange(selectedDate, range);
      const finalDate = new Date(selectedDate);

      if (range === "year") {
        for (let i = 11; i >= 0; i--) {
          const date = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - i,
            1
          );
          dates.push(date);
        }
        return dates;
      }

      while (currentDate <= finalDate) {
        dates.push(new Date(currentDate).toLocaleDateString());
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    }
  }, [range, selectedDate]);

  const labels = useMemo(() => {
    if (rangeDates) {
      if (range === "year") {
        return rangeDates.map((date) => {
          const rawDate = new Date(date).toLocaleDateString();
          // Split the date string into day, month, and year
          const parts = rawDate.toString().split("/");
          const month = parts[0];
          const year = parts[2];

          // Convert the month number to its corresponding month name
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          const monthName = monthNames[parseInt(month) - 1];

          // Create the new formatted date string
          return monthName + " " + year;
        });
      }

      return rangeDates.map(
        (date) => date
        //new Date(date).toLocaleDateString(theme.metrics.dateFormat)
      );
    }
  }, [range, rangeDates]);

  if (selectorDates.length === 0) {
    return <CircularProgress />;
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Speed m/s",
        data: averageWalkingSpeed.map(({ date, value }, index) => value),
        fill: false,
        borderColor: "#28666C",
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    elements: {
      point: {
        radius: 4,
        borderWidth: 2,
        backgroundColor: "#FFFFFF",
        borderColor: "#28666C",
      },
      line: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      backgroundSplitter: {
        colors,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 0.5,
          callback: (value: string, index: number, values: Array<string>) =>
            index !== 0 && index !== values.length - 1 ? `${value}m/s` : "",
        },
      },
    },
  };

  const onChangeRangeHandler = (value: string) => {
    setRange(value as rangeType);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Box sx={{ margin: "0px 40px 40px 40px" }}>
          <Typography sx={{ marginBottom: "40px" }} fontSize={13}>
            The walking speed is collected whilst the user is completing normal
            daily activities, averaged over the day. A change in walking speed
            is to be interpreted in relation to population norms, alongside
            clinical reasoning and understanding of the user and walking speed
            trends.
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
          >
            <Typography
              color="#28666C"
              fontSize="14px"
              sx={{ marginRight: "20px" }}
            >
              Period
            </Typography>
            <Dropdown
              initialValue={range}
              options={dropdownOptions}
              onSelect={onChangeRangeHandler}
            />
          </Box>
          {isDataLoading ? (
            <Skeleton animation="wave" />
          ) : (
            <Box sx={{ position: "relative", margin: "auto", width: "80vw" }}>
              {/*// @ts-ignore */}
              <Line data={data} options={{ ...options, responsive: true }} />
            </Box>
          )}
          <Box
            sx={{
              width: "402px",
              height: "366px",
              marginBottom: "30px",
            }}
          >
            <Box
              sx={{
                border: "3px solid #28666C",
                borderRadius: "14px 14px 0 0",
                backgroundColor: "#28666C",
              }}
            >
              <Typography textAlign="center" color="white" fontWeight={700}>
                Average Walking Speed
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: "center",
                flexGrow: 1,
                border: "3px solid #28666C",
                borderRadius: "0 0 14px 14px",
                paddingTop: "15px",
                paddingBottom: "10px",
              }}
            >
              <Grid sx={{ fontWeight: "500" }} container>
                <Grid item xs={4}>
                  Decade
                </Grid>
                <Grid item xs={4}>
                  Men (m/s)
                </Grid>
                <Grid item xs={4}>
                  Women (m/s)
                </Grid>
              </Grid>
              <div className="averageSpeedBox">
                <AverageSpeedItems />
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
function AverageSpeedItems() {
  const averageSpeedDataMock = [
    {
      decade: "20s",
      men: "1.358",
      women: "1.341",
    },
    {
      decade: "30s",
      men: "1.433",
      women: "1.337",
    },
    {
      decade: "40s",
      men: "1.434",
      women: "1.39",
    },
    {
      decade: "50s",
      men: "1.433",
      women: "1.313",
    },
    {
      decade: "60s",
      men: "1.339",
      women: "1.241",
    },
    {
      decade: "70s",
      men: "1.262",
      women: "1.132",
    },
    {
      decade: ">80s",
      men: "0.969",
      women: "0.943",
    },
  ];

  return (
    <>
      {averageSpeedDataMock.map(({ decade, men, women }, index) => {
        const styles = {
          backgroundColor: index % 2 === 0 ? "#F1F3F6" : "inherit",
          color: "#979797",
          display: "flex",
          justifyContent: "space-around",
          margin: "10px 0",
        };

        const itemStyle = {
          width: "33.333%",
          padding: "7px 0",
        };

        return (
          <div style={styles} className="decadesItem" key={decade}>
            <div style={itemStyle}>{decade}</div>
            <div style={itemStyle}>{men}</div>
            <div style={itemStyle}>{women}</div>
          </div>
        );
      })}
    </>
  );
}

export default AverageWalkingSpeedDetailContent;
