import React, { Dispatch, SetStateAction } from "react";
import { isSameDay } from "date-fns";
import {
  DatePicker,
  PickersDay,
  PickersDayProps,
  pickersDayClasses,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getAdjacentDate } from "../../../helpers/utilityFunctions/utilityFunctions";

enum Sequence {
  Next,
  Previous,
}

interface IMultiDatePickerProps {
  dates?: Date[];
  selectedDate?: Date | undefined;
  setSelectedDate?: Dispatch<SetStateAction<Date | undefined>>;
  setSelectedMonth?: Dispatch<SetStateAction<Date | undefined>>;
  isLoading?: boolean;
}

const MultiDatePicker = ({
  dates,
  selectedDate,
  setSelectedDate,
  setSelectedMonth,
  isLoading,
}: IMultiDatePickerProps) => {
  const [formattedDates, setFormattedDates] = React.useState<Date[]>([]);

  useEffect(() => {
    setFormattedDates([]);
    if (dates !== undefined) {
      const formattedDates = dates.map((date) => {
        return new Date(date);
      });
      setFormattedDates(formattedDates);
    }
  }, [dates]);

  const onAnyTextFieldChanged = (e: any) => {
    if (!!e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const CustomDay = (props: PickersDayProps<Date>) => {
    const matchedStyles = formattedDates.reduce((a, v) => {
      const date = new Date(props.day);
      return isSameDay(date, v)
        ? {
            backgroundColor: (theme: any) => theme.palette.primary.main,
            color: (theme: any) => theme.palette.common.white,
            "&:hover, &:focus": {
              backgroundColor: (theme: any) => theme.palette.primary.dark,
            },
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
          }
        : a;
    }, {});

    return (
      <PickersDay
        {...props}
        sx={{
          ...matchedStyles,
          [`&&.${pickersDayClasses.selected}`]: {
            border: 2,
            borderColor: (theme: any) => theme.palette.common.black,
            color: (theme: any) => theme.palette.common.black,
            background: (theme: any) => theme.palette.common.white,
          },
        }}
      />
    );
  };

  const handleMonthChange = (date: Date) => {
    if (!setSelectedMonth) return;
    setSelectedMonth(date);
  };

  const handleToggleDate = (sequence: Sequence) => {
    switch (sequence) {
      case Sequence.Next:
        let nextDate = getAdjacentDate(selectedDate!, "next");
        if (nextDate.getMonth() !== selectedDate!.getMonth()) {
          handleMonthChange(nextDate);
        }
        setSelectedDate!(nextDate);
        break;
      case Sequence.Previous:
        let previousDate = getAdjacentDate(selectedDate!, "previous");
        if (previousDate.getMonth() !== selectedDate!.getMonth()) {
          handleMonthChange(previousDate);
        }
        setSelectedDate!(previousDate);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tooltip title="Previous day">
        <span>
          <IconButton
            onClick={() => handleToggleDate(Sequence.Previous)}
            size="small"
            sx={{ mr: 1 }}
          >
            <ArrowLeftIcon />
          </IconButton>
        </span>
      </Tooltip>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          loading={isLoading}
          label="date"
          openTo="day"
          views={["day"]}
          value={selectedDate ?? new Date()}
          format="dd.MM.yyyy"
          slots={{ day: CustomDay }}
          onChange={(newValue) => {
            if (!setSelectedDate) return;
            setSelectedDate(newValue || undefined);
          }}
          onMonthChange={handleMonthChange}
          slotProps={{
            textField: {
              onBeforeInput: onAnyTextFieldChanged,
              InputProps: {
                size: "medium",
                readOnly: true,
              },
            },
          }}
        />
      </LocalizationProvider>
      <Tooltip title="Next day">
        <span>
          <IconButton
            onClick={() => handleToggleDate(Sequence.Next)}
            size="small"
            sx={{ ml: 1 }}
          >
            <ArrowRightIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default MultiDatePicker;
