import { Dispatch } from "redux";
import {
  FETCH_DAILY_METRIC_DATES,
  FETCH_DAILY_METRIC_DATES_SUCCESS,
  FETCH_DAILY_METRIC_DATES_FAILURE,
  FETCH_DAILY_METRICS,
  FETCH_DAILY_METRICS_SUCCESS,
  FETCH_DAILY_METRICS_FAILURE,
  CLEAR_SELECTED_DAILY_METRICS,
  FETCH_ALL_ALERT_DETAIL,
  FETCH_ALL_ALERT_DETAIL_SUCCESS,
  FETCH_ALL_ALERT_DETAIL_FAILURE,
  CLEAR_ALL_ALERT_DETAIL,
  SELECT_DAILY_METRIC_DATE,
} from "../constants";
import { PassiveParametersActionTypes } from "../types/passiveParametersActionTypes";
import { DailyMetrics } from "../../model/passive/dailyMetrics";
import { AlertMoment } from "../../model/passive/alertMoment";
import { Temperature } from "../../model/temperature";
import { celsiusToFahrenheit } from "../../helpers/utilityFunctions/utilityFunctions";
import dailyMetricsService from "../../services/DailyMetricsService";
import alertService from "../../services/AlertService";

//Dates

export function fetchDailyMetricDates(): PassiveParametersActionTypes {
  return {
    type: FETCH_DAILY_METRIC_DATES,
  };
}

export function fetchDailyMetricDatesSuccess(
  data: any
): PassiveParametersActionTypes {
  return {
    type: FETCH_DAILY_METRIC_DATES_SUCCESS,
    payload: data,
  };
}

export function fetchDailyMetricDatesError(
  error: string
): PassiveParametersActionTypes {
  return {
    type: FETCH_DAILY_METRIC_DATES_FAILURE,
    payload: error,
  };
}

export function getDailyMetricDates(deviceId: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchDailyMetricDates());

    return dailyMetricsService.getDailyMetricDates(deviceId).then(
      (response: any) => dispatch(fetchDailyMetricDatesSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function getDailyMetricDatesByRange(
  deviceId: string,
  startDate: string,
  endDate: string
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchDailyMetricDates());

    return dailyMetricsService
      .getDailyMetricDatesByRange(deviceId, startDate, endDate)
      .then(
        (response: any) => dispatch(fetchDailyMetricDatesSuccess(response))
        //error => dispatch(someerror('woody', buzz, error))
      );
  };
}

export function setDailyMetricDate(date: Date): PassiveParametersActionTypes {
  return {
    type: SELECT_DAILY_METRIC_DATE,
    payload: date,
  };
}

//Metrics

export function fetchDailyMetrics(): PassiveParametersActionTypes {
  return {
    type: FETCH_DAILY_METRICS,
  };
}

export function clearSelectedDailyMetrics(): PassiveParametersActionTypes {
  return {
    type: CLEAR_SELECTED_DAILY_METRICS,
  };
}

export function fetchDailyMetricsSuccess(
  data: DailyMetrics
): PassiveParametersActionTypes {
  return {
    type: FETCH_DAILY_METRICS_SUCCESS,
    payload: data,
  };
}

export function fetchDailyMetricsError(
  error: string
): PassiveParametersActionTypes {
  return {
    type: FETCH_DAILY_METRICS_FAILURE,
    payload: error,
  };
}

export function getDailyMetrics(
  patientId: string,
  date: Date,
  metrics: any = undefined
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchDailyMetrics());
    return dailyMetricsService.getDailyMetrics(patientId, date).then(
      (response: any) => {
        if (
          response.latestAlertsTemperature &&
          metrics &&
          metrics === Temperature.Fahrenheit
        ) {
          const sensorDataLeft: any[] = JSON.parse(
            response.latestAlertsTemperature.sensorDataLeft
          );
          const sensorDataRight: any[] = JSON.parse(
            response.latestAlertsTemperature.sensorDataRight
          );
          sensorDataLeft.forEach((o: any, i: any, a: any) => {
            a[i] = celsiusToFahrenheit(o);
          });
          sensorDataRight.forEach((o: any, i: any, a: any) => {
            a[i] = celsiusToFahrenheit(o);
          });

          response.latestAlertsTemperature.sensorDataLeft =
            JSON.stringify(sensorDataLeft);
          response.latestAlertsTemperature.sensorDataRight =
            JSON.stringify(sensorDataRight);
        }

        dispatch(fetchDailyMetricsSuccess(response));
      }
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

//Alerts - Could be split into its own actions?

export function fetchAllAlertDetail(): PassiveParametersActionTypes {
  return {
    type: FETCH_ALL_ALERT_DETAIL,
  };
}

export function fetchAllAlertDetailSuccess(
  data: Array<AlertMoment>
): PassiveParametersActionTypes {
  return {
    type: FETCH_ALL_ALERT_DETAIL_SUCCESS,
    payload: data,
  };
}

export function fetchAllAlertDetailError(
  error: string
): PassiveParametersActionTypes {
  return {
    type: FETCH_ALL_ALERT_DETAIL_FAILURE,
    payload: error,
  };
}

export function getAllAlertDetail(
  deviceId: string,
  type: string,
  metrics: any = undefined
) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchAllAlertDetail());
    alertService.getAlertDetailByType(deviceId, type).then(
      (response: any) => {
        if (metrics && metrics === Temperature.Fahrenheit) {
          response[0].data.forEach((alert: any, index: number) => {
            const sensorDataLeft: any[] = JSON.parse(alert.sensorDataLeft);
            const sensorDataRight: any[] = JSON.parse(alert.sensorDataRight);
            sensorDataLeft.forEach((o: any, i: any, a: any) => {
              a[i] = celsiusToFahrenheit(o);
            });
            sensorDataRight.forEach((o: any, i: any, a: any) => {
              a[i] = celsiusToFahrenheit(o);
            });
            response[0].data[index].sensorDataLeft =
              JSON.stringify(sensorDataLeft);
            response[0].data[index].sensorDataRight =
              JSON.stringify(sensorDataRight);
          });
        }
        dispatch(fetchAllAlertDetailSuccess(response));
      }
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

export function clearAllAlertDetails(): PassiveParametersActionTypes {
  return {
    type: CLEAR_ALL_ALERT_DETAIL,
  };
}
