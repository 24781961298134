import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CardContent, Grid } from "@mui/material";
import { ParameterCard } from "../../../documented/ParameterCard";
import { AverageSwingSpeedCardData } from "./averageSwingSpeedCardData";
import mySvg from "./averageSwingSpeedBackground.svg";
import { isEmpty } from "../../../../helpers/utilityFunctions/utilityFunctions";

interface AverageSwingSpeedCardProps {
  data?: AverageSwingSpeedCardData;
  onClick: (arg: string) => void;
}

const CardDetailTypography = styled(Typography)(() => ({
  fontSize: 14,
  padding: "0px 0px 0px 16px !important",
}));

const AverageSwingSpeedCardMedia = styled(CardContent)(() => ({
  display: "flex !important",
  flexDirection: "column",
  flexGrow: "1 !important",
}));

const AverageSwingSpeedCard: React.FC<AverageSwingSpeedCardProps> = ({
  data,
  onClick,
}: AverageSwingSpeedCardProps) => {
  return (
    <ParameterCard
      title="Average Swing Speed"
      iconName="AverageSwingSpeed"
      onClick={() => onClick("_10MWT")}
      enabled={!isEmpty(data)}
    >
      <CardDetailTypography>
        The maximum forward speed of the foot during swing
      </CardDetailTypography>
      <AverageSwingSpeedCardMedia>
        <Box
          sx={{
            backgroundImage: `url(${mySvg})`,
            height: 206.148,
            width: 173,
          }}
        >
          <Grid
            sx={{
              height: "100%",
              width: "100%",
              direction: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            container
          >
            <Grid
              item
              sx={{
                width: "86.5px",
                display: "flex",
                flexDirection: "column",
                margin: "0px 0px 0px -20px",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}
              >
                {data ? Number(data.left.value) : 0}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                {data ? data.left.unit : "s"}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                width: "86.5px",
                display: "flex",
                flexDirection: "column",
                margin: "0px -20px 0px 0px",
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: 20, textAlign: "center" }}
              >
                {data ? Number(data.right.value) : 0}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                {data ? data.left.unit : "s"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </AverageSwingSpeedCardMedia>
    </ParameterCard>
  );
};

export default AverageSwingSpeedCard;
